import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby-theme-material-ui'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import Call from "@material-ui/icons/Call";
import LocationOn from "@material-ui/icons/LocationOn";
import AccountCircle from "@material-ui/icons/AccountCircle";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Apartment from "@material-ui/icons/Apartment";
import HomeWork from "@material-ui/icons/HomeWork";
import DirectionsWalk from "@material-ui/icons/DirectionsWalk";

import Button from "components/CustomButtons/Button.js";

import {
  primaryColor,
  warningColor,
  successColor,
} from "assets/jss/material-kit-pro-react.js";

// img
import f_logo from "images/footer_logo.svg"

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";
const useStyles = makeStyles(styles);

const footerStyles = makeStyles((theme) => ({
  footer:{
  /* minHeight: '6em',*/
    fontSize: '.8em',
    backgroundColor: successColor[0],
    borderTop: 'solid 1px #999',
    '& > div':{
      padding: '1em 0 3em 0',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  table:{
    display: 'table',
    margin:'1em auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block!important',
      textAlign: 'center',
      '& > div':{
        '&:first-child':{
          display: 'block!important',
        },
        '&:last-child':{
          margin: '1em auto 0 auto',
          textAlign: 'left',
          display: 'inline-block!important',
          padding: '0',
          fontSize: '.8em',
          
        },
      },
    },
  },
  table2:{

  },
  list:{
    display: 'table-cell',
    verticalAlign: 'top',
    color: "#f0f0f0",
    listStyle: 'none',
    paddingLeft: '.5em',
    '& li':{
      margin: '.5em 1.5em .5em .5em',
      paddingRight: '1em',
      '&:first-child':{
        borderBottom: 'solid 1px #f0f0f0',
        paddingBottom: '.25em',
      },
      '& a':{
        color: "#f0f0f0",
        paddingLeft: '1em',
        textDecoration: 'none',
      },
      '& a:hover':{
        color: '#fff',
        textDecoration: 'underline',
        textDecorationColor: '#fff',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '.5em',
      },
    },
  },
  copyright:{
    fontSize: '.8em',
    backgroundColor: '#f0f0f0',
    color: "#666",
    textAlign: 'center',
  },
  f_shamei:{
    fontSize: '1.2em',
  },
  f_logo:{
    width: '12em',
  },
}))

const Footer = () => {
  const classes = useStyles()
  const cl = footerStyles()
  return (
    <footer className={cl.footer}>
      <div className={classes.container}>
        <div className={cl.table}>
          <div className={cl.list}>
            <img src={f_logo} id="logo" className={cl.f_logo} alt="有限会社 申源" /><br />
            〒440-0888 愛知県豊橋市駅前大通3丁目83<br /> 
            Tel：0532-56-2259<br/>
          </div>
          <div className={cl.list + " " + cl.table + " " + cl.table2}>
            <ul className={cl.list}>
              <li>物件案内</li>
              <li>
                <Link to href="/chintai" target="_blank">
                  賃貸物件
                </Link>
              </li>
              <li>
                <Link to href="/hanbai" target="_blank">
                  販売物件
                </Link>
              </li>
              <li>
                <Link to href="/parking" target="_blank">
                  貸駐車場
                </Link>
              </li>
            </ul>
            <ul className={cl.list}>
              <li>その他の事業</li>
              <li>
                <Link to href="/other" target="_blank">
                  各種保険
                </Link>
              </li>
              <li>
                <Link to href="https://akiya.shingen.info/" target="_blank">
                  空き家巡回サポーター
                </Link>
              </li>
              <li>
                <Link to href="/other" target="_blank">
                  リノベーション請負
                </Link>
              </li>
            </ul>
            <ul className={cl.list}>
              <li>申源について</li>
              <li>
                <Link to href="/company" target="_blank">
                  各種お問い合わせ
                </Link>
              </li>
              <li>
                <Link to href="/company#2" target="_blank">
                  ご来店のご案内
                </Link>
              </li>
              <li>
                <Link to href="/company#c3" target="_blank">
                  会社概要
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={cl.copyright} id="copyright">
        &copy; 2016 - {1900 + new Date().getYear()} &nbsp;SHINGEN CO.LTD.
      </div>
    </footer>
  )
}

export default Footer
